<template>
    <div>
        <v-card>
            <v-card-title class="sticky-header text-h5 py-2">
                <v-btn
                    v-if="closeButton"
                    class="mr-3"
                    depressed
                    color="primary"
                    icon
                    rounded
                    @click="closeDialog1()"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <span>{{ title }}</span>
                <v-spacer />
                <v-btn
                    :disabled="!validateForm"
                    color="primary"
                    :loading="valueLoading"
                    elevation="0"
                    rounded
                    @click="saveCost"
                    >Save</v-btn
                >
            </v-card-title>
            <v-divider class="mx-4" />

            <v-card-text class="overflow-y-auto" max-height="900">
                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <v-form v-model="valid">
                            <v-row no-gutters class="align-center">
                                <v-col
                                    class="d-flex align-center"
                                    cols="4"
                                    sm="2"
                                    md="2"
                                    lg="2"
                                    xl="2"
                                >
                                    <v-subheader>Reference *</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="10" md="10" lg="10" xl="10">
                                    <v-row>
                                        <v-text-field
                                            flat
                                            dense
                                            hide-details
                                            solo
                                            required
                                            :rules="[rules.required]"
                                            class="text-body-2"
                                            v-model="cost.reference"
                                            placeholder="Detailed name of the scope"
                                            :disabled="readOnly"
                                        ></v-text-field>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row no-gutters class="align-center">
                                <v-col
                                    class="d-flex align-center"
                                    cols="4"
                                    sm="2"
                                    md="2"
                                    lg="2"
                                    xl="2"
                                >
                                    <v-subheader>Category *</v-subheader>
                                </v-col>
                                <v-col
                                    class="d-flex align-center"
                                    cols="8"
                                    sm="10"
                                    md="10"
                                    lg="10"
                                    xl="10"
                                >
                                    <v-autocomplete
                                        flat
                                        dense
                                        hide-details
                                        solo
                                        required
                                        placeholder="Cost category for invoicing"
                                        :rules="[rules.required]"
                                        class="text-body-2 ml-n3"
                                        :items="categories"
                                        item-text="name"
                                        item-value="name"
                                        v-model="cost.category"
                                        :disabled="readOnly"
                                        style="max-width: 280px;"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row no-gutters class="d-flex align-center">
                                <v-col
                                    class="d-flex align-center"
                                    cols="4"
                                    sm="2"
                                    md="2"
                                    lg="2"
                                    xl="2"
                                >
                                    <v-subheader>Measure *</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="10" md="10" lg="10" xl="10">
                                    <v-autocomplete
                                        flat
                                        dense
                                        hide-details
                                        solo
                                        required
                                        value="ft2"
                                        :rules="[rules.required]"
                                        class="text-body-2 ml-n3"
                                        :items="units"
                                        @change="setPricePerQ"
                                        v-model="cost.measure"
                                        :disabled="readOnly"
                                        style="max-width: 150px;"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4" sm="2" md="2" lg="2" xl="2">
                                    <v-subheader>Units *</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="10" md="10" lg="10" xl="10">
                                    <v-text-field
                                        flat
                                        dense
                                        type="number"
                                        solo
                                        hide-details
                                        class="text-body-2 ml-n3"
                                        required
                                        :rules="[rules.number2]"
                                        placeholder="840"
                                        @input="setPricePerQ"
                                        v-model="cost.quantity"
                                        :disabled="readOnly"
                                        style="max-width: 150px;"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center" no-gutters>
                                <v-col cols="4" sm="2" md="2" lg="2" xl="2">
                                    <v-subheader>Margin *</v-subheader>
                                </v-col>
                                <v-col cols="8" sm="10" md="10" lg="10" xl="10">
                                    <v-text-field
                                        flat
                                        dense
                                        class="text-body-2 ml-n3"
                                        hide-details
                                        solo
                                        placeholder="44"
                                        type="number"
                                        suffix="%"
                                        required
                                        :rules="[rules.number]"
                                        @input="setTotalMargin"
                                        v-model="cost.margin"
                                        :disabled="readOnly"
                                        style="max-width: 150px;"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-start" no-gutters>
                    <v-col cols="4" sm="2" md="2" lg="2" xl="2">
                        <v-subheader>Description</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="10" md="10" lg="10" xl="10">
                        <v-textarea
                            hide-details
                            solo
                            rows="4"
                            dense
                            class="text-body-2 pt-2 ml-n3"
                            placeholder="Detailed description of the scope"
                            flat
                            v-model="cost.description"
                            :readonly="readOnly"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-divider class="my-4" />
                <NewCostForm
                    v-for="sec in costsSections"
                    :key="sec.name"
                    @totalCostFunction="totalCost"
                    @saveItems="saveSectionItems"
                    :quoteCurrency="quoteCurrency"
                    :quoteExchange="Number(quoteExchange)"
                    :createCost="value"
                    :section="sec"
                    :reload="reload"
                    :itemSections="cost.sections"
                    :settingsExchange="settingExchange"
                    :settingsUnits="settingUnits"
                    :quoteStatus="'OPEN'"
                    :version="version"
                />
                <v-simple-table dense class="mb-4">
                    <template v-slot:default>
                        <thead class="grey lighten-3">
                            <tr>
                                <th class="text-center no-border"></th>
                                <th class="text-center no-border">
                                    TOTAL COST
                                </th>
                                <th class="text-center no-border">
                                    MARGIN
                                </th>
                                <th class="text-center no-border">
                                    SALE PRICE
                                </th>
                                <th class="text-center no-border">
                                    COMMISSIONS
                                </th>
                                <th class="text-center no-border">
                                    UNITS
                                </th>
                                <th class="text-center no-border">
                                    PRICE PER UNIT
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">
                                    {{ settingExchange.globalCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            totalC
                                        )
                                    }}
                                    {{ settingExchange.globalCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            totalMargin
                                        )
                                    }}
                                    {{ settingExchange.globalCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.salePrice
                                        )
                                    }}
                                    {{ settingExchange.globalCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            commission
                                        )
                                    }}
                                    {{ settingExchange.globalCurrency }}
                                </td>
                                <td class="text-center">{{ cost.quantity }}</td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.pricePerQuantity.toFixed(2)
                                        )
                                    }}
                                    {{ settingExchange.globalCurrency }}/{{
                                        !isOperable
                                            ? cost.measure
                                            : isLength
                                            ? 'm'
                                            : 'm2'
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">
                                    {{ quoteCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.total2
                                        )
                                    }}
                                    {{ quoteCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.totalMargin2
                                        )
                                    }}
                                    {{ quoteCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.salePrice2
                                        )
                                    }}
                                    {{ quoteCurrency }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.commission2
                                        )
                                    }}
                                    {{ quoteCurrency }}
                                </td>
                                <td class="text-center">{{ cost.quantity }}</td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.pricePerQuantity2.toFixed(2)
                                        )
                                    }}
                                    {{ quoteCurrency }}/{{
                                        !isOperable
                                            ? cost.measure
                                            : isLength
                                            ? 'ft'
                                            : 'ft2'
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-simple-table dense class="mb-4">
                    <template v-slot:default>
                        <thead class="grey lighten-3">
                            <tr>
                                <th class="text-center no-border"></th>
                                <th class="text-center no-border">
                                    TOTAL WEIGHT
                                </th>
                                <th class="text-center no-border">
                                    WEIGHT PER UNIT
                                </th>
                                <th class="text-center no-border">
                                    COST/WEIGHT
                                </th>
                                <th class="text-center no-border">
                                    PRICE/WEIGHT
                                </th>
                                <th class="text-center no-border">
                                    COST PER UNIT
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>AMERICAN</td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.american.tw
                                        )
                                    }}
                                    lb
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.american.wa
                                        )
                                    }}
                                    {{
                                        cost.american.wa == 0
                                            ? ''
                                            : `lb/${
                                                  cost.measure === 'qt' ||
                                                  cost.measure === 'QT'
                                                      ? 'qt'
                                                      : isLength
                                                      ? 'ft'
                                                      : 'ft2'
                                              }`
                                    }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.american.cw
                                        )
                                    }}
                                    {{ quoteCurrency }}/lb
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.american.pw
                                        )
                                    }}
                                    {{ quoteCurrency }}/lb
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.american.ca
                                        )
                                    }}
                                    {{ quoteCurrency }}/{{
                                        !isOperable
                                            ? cost.measure
                                            : isLength
                                            ? 'ft'
                                            : 'ft2'
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>INTERNATIONAL</td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.international.tw
                                        )
                                    }}
                                    kg
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.international.wa
                                        )
                                    }}
                                    {{
                                        cost.international.wa == 0
                                            ? ''
                                            : `kg/${
                                                  cost.measure === 'qt' ||
                                                  cost.measure === 'QT'
                                                      ? 'qt'
                                                      : isLength
                                                      ? 'm'
                                                      : 'm2'
                                              }`
                                    }}
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.international.cw
                                        )
                                    }}
                                    COP/kg
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.international.pw
                                        )
                                    }}
                                    COP/kg
                                </td>
                                <td class="text-center">
                                    {{
                                        new Intl.NumberFormat('de-DE').format(
                                            cost.international.ca
                                        )
                                    }}
                                    COP/{{
                                        !isOperable
                                            ? cost.measure
                                            : isLength
                                            ? 'm'
                                            : 'm2'
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
        <v-dialog
            :retain-focus="false"
            v-model="dialogSave"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5">Save Cost</v-card-title>
                <v-card-text>
                    Do you want to save the changes?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="dontSaveCost">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="saveCost" text color="error">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-alert
            :type="action.type"
            :color="action.color"
            style="position: absolute; bottom: 0; right: 0;"
            dismissible
            v-if="action.active && action.type == 'error'"
        >
            {{ action.message }}
        </v-alert>
    </div>
</template>

<script>
import NewCostForm from '@/components/Quotes/NewCostForm'
import { mapActions } from 'vuex'
import convert from 'convert-units'
import _ from 'lodash'
//import API from '@/services/api'
import { objDiff } from '@/helpers/objDiff.js'
//import moment from 'moment'

export default {
    name: 'NewQuoteCostForm',
    components: {
        NewCostForm,
    },
    props: {
        title: String,
        value: {
            type: Boolean,
            default() {
                return true
            },
        },
        editScope: {
            type: Boolean,
            default() {
                return false
            },
        },
        settings: Array,
        cost: Object,
        originalCost: Object,
        costs: Array,
        loading: Boolean,
        closeButton: {
            type: Boolean,
            default() {
                return true
            },
        },
        version: {
            type: Boolean,
            default() {
                return false
            },
        },
        costExchanges: {
            type: Object,
            default() {
                return undefined
            },
        },
        currency: {
            type: String,
            default() {
                return ''
            },
        },
        exchange: {
            type: Number,
            default() {
                return 1
            },
        },
        action: {
            type: Object,
            default() {
                return {}
            },
        },
        readOnly: {
            type: Boolean,
            default() {
                return false
            },
        },
        aproved: {
            type: Boolean,
            default() {
                return true
            },
        },
    },
    data() {
        return {
            isSaved: false,
            units: [],
            newTotal: 0,
            quoteCurrency: 'USD',
            quoteExchange: 4100,
            newItem: {},
            dialogSave: false,
            settingExchange: [],
            sumCommissions: 0,
            totalWeight: this.cost.tw,
            totalMargin: this.cost.totalMargin,
            total: this.cost.total,
            totalSections: this.cost.totalSections,
            totalCommission: this.cost.commission,
            costsSections: [],
            descriptionCost: this.cost.descriptionCost,
            valid: false,
            copyOriginalScope: {},
            enableSave: false,
            userId: JSON.parse(localStorage.getItem('userId')),
            rules: {
                required: v => !!v || 'Required',
                number: v => (v <= 100 && v >= 0) || 'Error',
                number2: v => v > 0 || 'Error',
                string:
                    (v => (typeof v == 'string' && v != '') || !v) || 'Error',
            },
            quantityInternational: 0,
            isLength: false,
            reload: false,
            isOperable: true,
            categories: [],
        }
    },
    created() {
        const indexCosts = this.settings.findIndex(u => u.name === 'Quotes')
        this.costsSections = this.settings[indexCosts].costSections
        if (!this.cost.sections) {
            this.cost.sections = []
        }
        this.settings[indexCosts].measureUnits.forEach(x => {
            this.units.push(x.abbr)
        })
        if (this.costExchanges) {
            this.settingExchange = this.costExchanges
        } else {
            const indexExchanges = this.settings.findIndex(
                u => u.name === 'Exchange'
            )
            this.settingExchange = this.settings[indexExchanges]
        }
        this.settingUnits = this.settings[indexCosts]
    },
    async mounted() {
        const invoiceSettings = this.settings.find(
            setting => setting.name.toLowerCase() == 'invoicing'
        )
        this.categories = invoiceSettings.categories
    },
    computed: {
        totalC: function() {
            return this.total
        },
        costDiff: function() {
            let diff = objDiff(this.originalCost, this.cost)
            delete diff.pricePerQuantity
            delete diff.pricePerQuantity2
            delete diff.salePrice
            delete diff.salePrice2
            delete diff.american
            delete diff.international
            delete diff.total2
            delete diff.totalmargin2
            delete diff.createdOn

            return (
                Object.keys(diff).length > 0 &&
                this.valid &&
                this.cost.sections.find(section => section.items.length > 0)
            )
        },
        commission: function() {
            return this.totalCommission
        },
        valueLoading: function() {
            return this.loading
        },
        validateForm: function() {
            let diff = {}
            if (!this.isSaved) {
                diff = objDiff(this.originalCost, this.cost)
            } else {
                diff = objDiff(this.copyOriginalScope, this.cost)
            }
            //let diff = objDiff(this.originalCost, this.cost)
            delete diff.pricePerQuantity
            delete diff.pricePerQuantity2
            delete diff.salePrice
            delete diff.salePrice2
            delete diff.american
            delete diff.international
            delete diff.total2
            delete diff.totalmargin2
            delete diff.createdOn
            return (
                Object.keys(diff).length > 0 &&
                this.valid &&
                this.cost.sections.find(section => section.items.length > 0)
            )
        },
    },
    watch: {
        totalC: function(newTotal) {
            this.totalCostAction(newTotal)
        },
    },
    methods: {
        ...mapActions(['totalCostAction', 'scopeInfo']),
        totalCost(parcial, sectionName, totalW) {
            if (
                this.totalSections == undefined ||
                this.totalSections == null ||
                this.cost.sections.length != this.totalSections.length
            ) {
                const newTotalSections = []
                for (const section of this.cost.sections) {
                    let totalSection = {
                        secName: section.name,
                        total: 0,
                        totalW: 0,
                    }
                    for (const item of section.items) {
                        totalSection.total += item.parcial
                        totalSection.totalW += item.totalWeight
                    }
                    newTotalSections.push(totalSection)
                }
                this.totalSections = newTotalSections
            }
            const indexSection = this.totalSections.findIndex(
                element =>
                    element.secName.toLowerCase() == sectionName.toLowerCase()
            )
            this.newItem = {
                secName: sectionName,
                total: parcial,
                totalW: totalW,
            }
            if (indexSection >= 0) {
                this.totalSections.splice(indexSection, 1, this.newItem)
            } else {
                this.totalSections.push(this.newItem)
            }
            this.total = 0
            this.totalWeight = 0
            this.totalSections.forEach(element => {
                this.total +=
                    element.secName !== 'Engineering & Desing'
                        ? element.total
                        : 0
                this.totalWeight +=
                    element.secName !== 'Engineering & Desing'
                        ? element.totalW
                        : 0
            })
            this.cost.total = this.total
            this.cost.international.tw = this.totalWeight
            this.cost.american.tw = parseFloat(this.totalWeight * 2.20462)
            this.setTotalMargin(this.cost.margin)
            this.setPricePerQ(this.cost.quantity)
        },

        setTotalMargin(event) {
            if (this.cost.total != undefined) {
                const marginp = event / 100
                const item1 = this.cost.total / (1 - marginp)
                const item2 = item1 * (this.sumCommissions / 100)
                const salesPrice1 = item1 + item2
                const salesPrice = item1 + item2
                this.totalCommission = salesPrice * (this.sumCommissions / 100)
                this.cost.commission = parseFloat(this.totalCommission)
                let salesPrice2 = salesPrice1
                const margin =
                    salesPrice - this.totalCommission - this.cost.total
                this.totalMargin = margin
                this.cost.totalMargin = parseFloat(this.totalMargin)
                this.cost.salePrice = parseFloat(salesPrice2)
                this.cost.total2 = parseFloat(
                    this.cost.total / this.quoteExchange
                )
                this.cost.totalMargin2 = parseFloat(
                    this.cost.totalMargin / this.quoteExchange
                )
                this.cost.salePrice2 = parseFloat(
                    this.cost.salePrice / this.quoteExchange
                )
                this.cost.commission2 = parseFloat(
                    this.cost.commission / this.quoteExchange
                )

                this.convertUnits(this.cost.quantity)

                this.cost.pricePerQuantity = parseFloat(
                    this.cost.salePrice / this.quantityInternational
                )

                this.cost.pricePerQuantity2 = parseFloat(
                    this.cost.salePrice2 / this.cost.quantity2
                )

                this.setAmericanStatistics()
                this.setInternationalStatistics()
            }
        },
        closeDialog: function() {
            this.enableSave = false
            this.totalMargin = 0
            this.totalCommission = 0
            this.totalSections = []
            this.$emit('closeDialog')
        },
        closeDialog1: function() {
            if (this.costDiff) {
                if (Object.entries(this.costDiff).length == 0) {
                    this.closeDialog()
                } else {
                    this.dialogSave = true
                }
            } else {
                if (this.version) {
                    this.dialogSave = false
                    this.closeDialog()
                } else {
                    this.total = 0
                    this.totalSections = []
                    this.dialogSave = false
                    this.closeDialog()
                }
            }
        },
        setPricePerQ(event) {
            if (isNaN(parseFloat(event))) {
                event = this.cost.quantity
            }
            this.convertUnits(event)

            this.cost.pricePerQuantity = parseFloat(
                this.cost.salePrice / this.quantityInternational
            )
            this.cost.pricePerQuantity2 = parseFloat(
                this.cost.salePrice2 / this.cost.quantity2
            )

            this.convertToQuoteCurrency()
            this.setAmericanStatistics()
            this.setInternationalStatistics()
        },
        setAmericanStatistics() {
            if (this.cost.american.tw > 0) {
                this.cost.american.cw = parseFloat(
                    (this.cost.total2 / this.cost.american.tw).toFixed(2)
                )
                this.cost.american.pw = parseFloat(
                    (this.cost.salePrice2 / this.cost.american.tw).toFixed(2)
                )
            }
            if (this.cost.quantity > 0) {
                if (this.cost.american.tw > 0) {
                    this.cost.american.wa = parseFloat(
                        (this.cost.american.tw / this.cost.quantity2).toFixed(2)
                    )
                }
                this.cost.american.ca = parseFloat(
                    (this.cost.total2 / this.cost.quantity2).toFixed(2)
                )
            }
            if (this.cost.american.tw === 0) {
                this.cost.american.wa = 0
                this.cost.american.cw = 0
                this.cost.american.pw = 0
            }
        },
        setInternationalStatistics() {
            if (this.cost.international.tw > 0) {
                this.cost.international.cw = parseFloat(
                    (this.cost.total / this.cost.international.tw).toFixed(2)
                )
                this.cost.international.pw = parseFloat(
                    (this.cost.salePrice / this.cost.international.tw).toFixed(
                        2
                    )
                )
            }

            if (this.cost.quantity > 0) {
                if (this.cost.international.tw > 0) {
                    this.cost.international.wa = parseFloat(
                        (
                            this.cost.international.tw /
                            this.quantityInternational
                        ).toFixed(2)
                    )
                }
                this.cost.international.ca = parseFloat(
                    (this.cost.total / this.quantityInternational).toFixed(2)
                )
            }

            if (this.cost.international.tw === 0) {
                this.cost.international.wa = 0
                this.cost.international.cw = 0
                this.cost.international.pw = 0
            }
        },
        convertToQuoteCurrency() {
            if (this.quoteCurrency != this.settingExchange.globalCurrency) {
                this.cost.total2 = parseFloat(
                    (this.total / this.quoteExchange).toFixed(2)
                )
                this.cost.totalMargin2 = parseFloat(
                    (this.totalMargin / this.quoteExchange).toFixed(2)
                )
                this.cost.salePrice2 = parseFloat(
                    (this.cost.salePrice / this.quoteExchange).toFixed(2)
                )
                this.cost.commission2 = parseFloat(
                    (this.cost.commission / this.quoteExchange).toFixed(2)
                )
                this.cost.pricePerQuantity2 = parseFloat(
                    this.cost.salePrice2 / this.cost.quantity2
                )
            } else {
                this.cost.total2 = this.total
                this.cost.totalMargin2 = this.totalMargin
                this.cost.salePrice2 = this.cost.salePrice
                this.cost.commission2 = this.cost.commission
                this.cost.pricePerQuantity2 = this.cost.pricePerQuantity
            }
        },
        dontSaveCost() {
            this.dialogSave = false
            this.closeDialog()
        },
        async saveCost() {
            try {
                this.descriptionCost = ''
                this.cost.sections.forEach(element => {
                    for (const i of element.items) {
                        if (this.descriptionCost) {
                            this.descriptionCost =
                                this.descriptionCost + ',' + i.name
                        } else {
                            this.descriptionCost = i.name
                        }
                    }
                })
                this.cost.descriptionCost = this.descriptionCost
                this.descriptionCost = null
                if (this.costs) {
                    const indexCost = this.costs.findIndex(
                        element => element.id == this.cost.id
                    )

                    if (indexCost >= 0) {
                        this.costs.splice(indexCost, 1, this.cost)
                    }
                }

                if (this.editScope) {
                    const payload = {
                        type: 'update',
                        data: this.cost,
                        userId: this.userId,
                    }
                    await this.scopeInfo(payload)
                    this.isSaved = true
                    this.copyOriginalScope = _.cloneDeep(this.cost)
                } else {
                    const payload = {
                        type: 'create',
                        data: this.cost,
                        userId: this.userId,
                    }
                    await this.scopeInfo(payload)
                }
                this.dialogSave = false
                this.closeDialog()
            } catch (error) {
                console.error(error)
            }
        },
        saveSectionItems(sectionName, sectionItems) {
            if (sectionName === 'Engineering & Desing') return
            const section = { name: sectionName, items: sectionItems }
            const indexSection = this.cost.sections.findIndex(
                element => element.name == sectionName
            )
            if (indexSection >= 0) {
                this.cost.sections.splice(indexSection, 1, section)
            } else {
                this.cost.sections.push(section)
            }
            this.cost.totalSections = this.totalSections
        },
        convertUnits(quantity) {
            if (this.cost.measure == 'qt' || this.cost.measure == 'QT') {
                this.isOperable = false
                this.cost.quantity2 = quantity
                this.quantityInternational = quantity
            } else if (
                this.settingUnits.measureUnits.find(
                    unit =>
                        unit.abbr == this.cost.measure && unit.measure == 'time'
                )
            ) {
                this.isOperable = false
                this.cost.quantity2 = quantity
                this.quantityInternational = quantity
            } else if (
                this.settingUnits.measureUnits.find(
                    unit =>
                        unit.abbr == this.cost.measure && unit.measure == 'mass'
                )
            ) {
                this.isOperable = false
                this.cost.quantity2 = quantity
                this.quantityInternational = quantity
            } else if (this.cost.measure == 'yd2') {
                this.isOperable = true
                this.cost.quantity2 = parseFloat(
                    (quantity / 0.111111).toFixed(2)
                )
                this.quantityInternational = parseFloat(
                    (quantity / 1.19599).toFixed(2)
                )
            } else if (
                this.settingUnits.measureUnits.find(unit => {
                    if (
                        unit.abbr == this.cost.measure &&
                        unit.measure == 'length'
                    ) {
                        this.isOperable = true
                        this.isLength = true
                        return true
                    }
                })
            ) {
                this.cost.quantity2 = parseFloat(
                    convert(quantity)
                        .from(this.cost.measure)
                        .to('ft')
                )
                this.quantityInternational = parseFloat(
                    convert(quantity)
                        .from(this.cost.measure)
                        .to('m')
                )
            } else {
                this.isOperable = true
                this.isLength = false
                this.cost.quantity2 = parseFloat(
                    convert(quantity)
                        .from(this.cost.measure)
                        .to('ft2')
                )
                this.quantityInternational = parseFloat(
                    convert(quantity)
                        .from(this.cost.measure)
                        .to('m2')
                )
            }
        },
    },
}
</script>

<style scoped>
.no-border {
    border: none !important;
}
.v-textarea >>> textarea {
    line-height: 22px !important;
}
tr:not(:last-child) > td.border-grey {
    border-bottom: thin solid #eeeeee !important;
}
tr:hover {
    background: unset !important;
}
th,
td {
    font-size: 0.875rem !important;
}
.v-data-table >>> table {
    border: thin solid #eeeeee;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
    padding: 0;
}
.sticky-header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
</style>
